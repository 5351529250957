import React, { useEffect, useRef, useState, FC } from 'react';
import { Container, Nav } from 'react-bootstrap';
import parse from 'html-react-parser';
import checkScrollPosition from '../../../helpers/checkScrollPosition';
import _ from 'lodash';
import { useFeatureToggles } from '../../../contexts/FeatureTogglesContext';
import { DetailSectionType } from '../../../types/DetailSectionType';
import { axiosNoAuth } from '../../../services/axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getDetailTranslations } from '../../../services/HomePageServices';
import iconCalender from '../../../assets/images/icon/icon-calendar.svg';
import iconLocation from '../../../assets/images/icon/icon-location.svg';
import { initialGoDown, transition, whileInView } from '../../../helpers/animations';
import { motion } from 'framer-motion';
import { conversion, triggerEvent } from '../../../helpers/cxDataLayer';

function EventDetailSection({ contentId, animationTiming, amplifier, translateLimit }: DetailSectionType) {
  const [isCurrentPosition, setIsCurrentPosition] = useState(false);
  const [translateFactor, setTranslateFactor] = useState(-30);
  const [windowInnerWidth, setWindowInnerWidth] = useState(0);
  const { application } = useFeatureToggles();
  const { closed } = useFeatureToggles();
  const { t } = useTranslation(['detail', 'header']);
  const {data: detailData, isLoading} = useQuery({
    queryKey: ['detail', 'header'],
    queryFn: () => getDetailTranslations(axiosNoAuth),
    staleTime: Infinity
  });

  const ref = useRef(null);
  const applicationRemark = useTranslation('header').t('application_remarks_abbr');
  const applicationLink = useTranslation('header').t('application_link');

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', () => {
      updateWindowDimension();
    });
    updateWindowDimension();
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', handleAnimation);
      window.removeEventListener('resize', updateWindowDimension);
    };
  }, []);

  useEffect(() => {
    handleAnimationAfterResize();
  }, [windowInnerWidth]);

  const updateWindowDimension = () => {
    setWindowInnerWidth(window.innerWidth);
  };

  const handleScroll = _.debounce(() => {
    checkScrollPosition(
      ref.current,
      animationTiming,
      () => {
        setIsCurrentPosition(true);

        // Only trigger carousel animation in large viewport
        if (windowInnerWidth >= 1024) {
          window.addEventListener('scroll', handleAnimation);
        }
      },
      () => {
        setIsCurrentPosition(false);
      }
    );
  }, 25);

  const handleAnimation = () => {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    const relativePosition = scrollPosition - animationTiming;

    // Amplify scroll distance to generate factor
    let translateFactor = relativePosition * amplifier * -1;
    if (translateFactor < translateLimit) {
      translateFactor = translateLimit;
    } else if (translateFactor > 0) {
      translateFactor = 0;
    }
    setTranslateFactor(translateFactor);
  };

  const handleAnimationAfterResize = () => {
    // Only trigger carousel animation in large viewport
    if (windowInnerWidth >= 1024) {
      handleAnimation();
    }
  };

  if (!detailData || isLoading) return null;

  return (
    <section
      ref={ref}
      id="eventdetail"
      className='event-detail with-collage'
    >
      <Container className='event-detail__container'>
        <div className="event-detail__top row">
          <div className={'event-detail__content-col col-lg-4'}>
            <div className='event-detail__detail-group'>
              <motion.h2
                initial={initialGoDown}
                whileInView={whileInView}
                transition={transition}
                className='event-detail__title'
              >
                {t('event_title')}
              </motion.h2>
              <motion.div
                initial={initialGoDown}
                whileInView={whileInView}
                transition={transition}
                className='event-detail__description'
              >
                {!isLoading && parse(t('event_description'))}
              </motion.div>
            </div>
            <div className='event-detail__detail-group'>
              <motion.div
                initial={initialGoDown}
                whileInView={whileInView}
                transition={transition}
                className='event-detail__detail'
              >
                <img
                  className='event-detail__icon'
                  src={iconCalender}
                  alt=""
                />
                {t('event_date_range')}
              </motion.div>
              <motion.div
                initial={initialGoDown}
                whileInView={whileInView}
                transition={transition}
                className='event-detail__detail'
              >
                <img
                  className='event-detail__icon'
                  src={iconLocation}
                  alt=''/>
                {t('event_venue')}
              </motion.div>
            </div>
            <div className='event-detail__detail-group'>
              <motion.div
                initial={initialGoDown}
                whileInView={whileInView}
                transition={transition}
                className='event-detail__apply-button-wrapper'
              >
                {application ? (
                  <>
                    <Nav.Link
                      className="event-detail__apply-button"
                      href= {applicationLink}
                      target="_blank"
                      onClick={() => {
                        conversion();
                        triggerEvent({
                          event:
                            {
                              event_action: "CATHAY_HACKATHON::APPLY_NOW_BTN"
                            }
                        });
                      }}
                    >
                      {t('application_button_text', { ns: 'header' })}
                    </Nav.Link>
                    <div>{parse(applicationRemark)}</div>
                  </>
                ) : null}

                { closed ? (
                  <div>{parse(t('event_closed_text'))}</div>
                ) : null}

              </motion.div>
              {/* <motion.div
                initial={initialGoDown}
                whileInView={whileInView}
                transition={transition}
                className='event-detail__detail'>
                {!isLoading && parse(applicationRemark)}
              </motion.div> */}
            </div>
          </div>
          <motion.div
            initial={initialGoDown}
            whileInView={whileInView}
            transition={transition}
            className="event-detail__img-wrapper col col-12 col-lg-8">
            <img
              className={'event-detail__img'}
              src={t('event_image')}
              alt={t('event_image_alt_text')}
            />
          </motion.div>
        </div>
        <div className="event-detail__cards">
          {detailData?.map((_, index) => (
            <EventDetailCard
              key={index}
              EventCardIndex={index}
            />
          ))}
        </div>
      </Container>
    </section>
  );
}

export default EventDetailSection;

interface EventCardProps{
  EventCardIndex: number;
}

const EventDetailCard: FC<EventCardProps> = ({ EventCardIndex }) => {
  const { t } = useTranslation('detail');
  return(
    <motion.div
      initial={initialGoDown}
      whileInView={whileInView}
      transition={transition}
      className="card_container"
    >
      <div className='card_container__icon_col'>
        <img
          className='card_container__icon'
          src={t(`event_card_icon_link_${EventCardIndex}`)}
          alt=''
        />
      </div>
      <div className='card_container__content_col'>
        <h3 className='card_container__title'>
          {t(`event_card_title_${EventCardIndex}`)}
        </h3>
        <div>
          {t(`event_card_description_${EventCardIndex}`)}
        </div>
      </div>
    </motion.div>
  );
};