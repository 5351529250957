export const initialGoDown = {
  opacity: 0,
  y: '-1rem',
};

export const initialGoUp = {
  opacity: 0,
  y: '1rem',
};

export const whileInView = {
  opacity: 1,
  y: '0rem',
};
export const transition = {
  duration: 0.5,
  ease: 'easeOut',
};