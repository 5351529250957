import { useState } from 'react';
import brandLogo from '../../assets/images/cathay.svg';
import FAQicon from '../../assets/images/FAQicon.svg';
import RecapIcon from '../../assets/images/icon/icon-calendar.svg';
import PortalIcon from '../../assets/images/portal-icon.svg';
import brushwingLogo from '../../assets/images/cx_brushwing_logo.originalimage.png';
import { Navbar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { useScroll, useMotionValueEvent, AnimatePresence, motion } from 'framer-motion';
import { scrollToTop } from '../../helpers/scroll';
import { useFeatureToggles } from '../../contexts/FeatureTogglesContext';
import { NavHashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getHeaderTranslations } from '../../services/NavigationItemServices';
import { axiosNoAuth } from '../../services/axios';
import { LanguageSelectorBanner } from '../LanguageSelectorBanner/LanguageSelectorBanner';
import { triggerEvent } from '../../helpers/cxDataLayer';
import { conversion } from '../../helpers/cxDataLayer';

interface ResponsiveNavbarProps {
  pathName: string;
}

interface NavbarTopRibbonProps {
  openSideMenuHandler: () => void;
}

interface SideMenuProps {
  showMenu: boolean;
  closeSideMenuHandler: () => void;
  pathName: string;
  headerTranslations: number[];
}

interface SideLinksProps {
  closeSideMenuHandler: () => void;
  pathName: string;
  headerTranslations: number[];
}

const scrollWithOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -106; // Sorry to hard code this
  window.scrollTo({ top: yCoordinate + yOffset,
    behavior: 'smooth' });
};

const tabName = ['DETAIL', 'HIGHLIGHT', 'JOURNEY', 'PRIZES', 'ABOUT', 'FAQ'];

const linkEvent = (clickEvent: string) => {
  triggerEvent({
    event:
      {
        event_action: 'CATHAY_HACKATHON::' + clickEvent
      }
  });
};

function ResponsiveNavbar({ pathName }: ResponsiveNavbarProps) {
  const [isScrollDown, setIsScrollDown] = useState(false);
  const [isOnTop, setIsOnTop] = useState(true);
  const [isAnimatedSticky, setIsAnimatedSticky] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { scrollY } = useScroll();
  const { data: headerTranslations, isLoading } = useQuery({
    queryKey: ['header'],
    queryFn: () => getHeaderTranslations(axiosNoAuth),
    staleTime: Infinity
  });

  useMotionValueEvent(scrollY, 'change', () => {
    // Toggle the stickyness by scroll direction
    // Magic number for the height of the navbar
    const isSmallScreen = window.innerWidth < 1024;
    const navBarHeight = isSmallScreen ? 46 : 106;

    if (scrollY.get() > navBarHeight) {
      setIsOnTop(false);

      // Stop transition at the moment that just scroll down from the top
      if (scrollY.getPrevious() <= navBarHeight) {
        setIsAnimatedSticky(false);
      } else {
        setIsAnimatedSticky(true);
      }
    } else {
      setIsOnTop(true);
      setIsAnimatedSticky(false);
    }

    if (scrollY.get() < scrollY.getPrevious()) {
      setIsScrollDown(false);
    } else if (
      scrollY.get() > navBarHeight &&
      scrollY.get() > scrollY.getPrevious()
    ) {
      setIsScrollDown(true);
    }
  });

  const openSideMenuHandler = () => {
    if (!showMenu) {
      setShowMenu(true);
      if (typeof window != 'undefined' && window.document) {
        document.body.style.overflow = 'hidden';
      }
    }
  };

  const closeSideMenuHandler = () => {
    if (showMenu) {
      setShowMenu(false);
      if (typeof window != 'undefined' && window.document) {
        document.body.style.overflow = '';
      }
    }
  };

  if (isLoading || !headerTranslations) {
    return null;
  }

  return (
    <>
      <nav
        className={`navbar-wrapper${isOnTop ? '' : ' sticky'}${
          isScrollDown ? '' : ' sticky-show'
        }${isAnimatedSticky ? ' sticky-transition' : ''}`}
        id="responsive-navbar"
      >
        <LanguageSelectorBanner />
        <NavbarTopRibbon openSideMenuHandler={openSideMenuHandler} />
        <NavbarPrimary headerTranslations={headerTranslations} />
        <SideMenu
          showMenu={showMenu}
          closeSideMenuHandler={closeSideMenuHandler}
          pathName={pathName}
          headerTranslations={headerTranslations}
        />
        <div
          className={`dark-overlay ${showMenu ? 'showMenu' : 'hideMenu'}`}
          onClick={closeSideMenuHandler}
        ></div>
      </nav>
      <div className={`header-placeholder ${isOnTop ? '' : 'show'}`}></div>
    </>
  );
}

function NavbarTopRibbon({ openSideMenuHandler }: NavbarTopRibbonProps) {
  const { t, i18n } = useTranslation('header');
  const { portal } = useFeatureToggles();

  return (
    <Navbar className="navbar-top-ribbon">
      <div className="navbar-top-ribbon__container">
        <div className="navbar-top-ribbon__out-left-group">
          <Nav.Link id="recap" as={Link} to={'/' + i18n.language + t('recap_link') + '?cxsource=HACKATHON_HISTORY_LINK'} className="navbar-top-ribbon__button">
            <img className="navbar-top-ribbon__recap__icon" src={RecapIcon} alt={t('recap_icon_alt_text')}></img>
            {t('recap_text')}
          </Nav.Link>
        </div>
        <div className="navbar-top-ribbon__left-group">
          <div
            className="navbar-top-ribbon__burger-menu-button"
            onClick={openSideMenuHandler}
          >
            <svg
              height="17px"
              viewBox="0 0 22 19"
              width="20px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.16 16.36a.84.84 0 110 1.68H1.84a.84.84 0 110-1.68h18.32zm0-7.68a.84.84 0 110 1.68H1.84a.84.84 0 110-1.68h18.32zm0-7.68a.84.84 0 110 1.68H1.84a.84.84 0 110-1.68h18.32z"
                fill="currentColor"
                fillRule="nonzero"
                stroke="currentColor"
                strokeDasharray="0,0"
                strokeWidth="0.8"
              ></path>
            </svg>
            <span className="visually-hidden">menu</span>
          </div>
        </div>

        <Link
          className="navbar-top-ribbon__homelink"
          to={'/' + i18n.language}
          rel="nofollow noopener noreferrer"
          onClick={scrollToTop}
        >
          <div className="navbar-top-ribbon__homelink__icon-wrapper">
            <img
              className="navbar-top-ribbon__homelink__icon"
              src={brushwingLogo}
              alt="Brushwing"
              width="23.19"
              height="28"
            />
            <span className="visually-hidden">brushwing</span>
          </div>
        </Link>
        <div className="navbar-top-ribbon__right-group">
          {portal ? (
            <Nav.Link
              className="navbar-top-ribbon__button"
              href = {t('portal_link')}
            >
              <img className="navbar-top-ribbon__portal__icon" src={PortalIcon} alt="Portal Icon"/>
              {t('portal_text')}
              <div className="navbar-top-ribbon__separator">|</div>
            </Nav.Link>
          ) : null}
          <Nav.Link
            className="navbar-top-ribbon__button"
            href = {t('support_link')}
            onClick={() => linkEvent('SUPPORT_LINK')}
          >
            <img className="navbar-top-ribbon__faq__icon" src={FAQicon} alt={t('support_icon_alt_text')}></img>
            {t('support_text')}
          </Nav.Link>
        </div>
      </div>
    </Navbar>
  );
}

function NavbarPrimary({headerTranslations}: {headerTranslations: number[]}) {
  const { application } = useFeatureToggles();
  const { i18n } = useTranslation();
  const { t } = useTranslation('header');

  return (
    <Navbar className="navbar-primary">
      <div className="navbar-primary__container">
        <div className="navbar-primary__left-group">
          <Link
            className="navbar-primary__homelink"
            to={`/${i18n.language}`}
            onClick={scrollToTop}
          >
            <img
              className="navbar-primary__homelink__icon"
              src={t('cathay_logo')}
              alt="Cathay Pacific"
              id="statisticsSection"
            />
          </Link>
          {headerTranslations?.map((value, index) => {
            return (
              <Nav.Link
                key={index}
                className="navbar-primary__nav-link"
                as={Link}
                smooth to={t('navigation_link_' + index)}
                scroll={scrollWithOffset}
                onClick={() => linkEvent(tabName[index]+'_TAB')}
              >
                {t('navigation_title_' + index)}
              </Nav.Link>
            );
          })}
        </div>
        <div className="navbar-primary__right-group">
          {
            application ? (
              <Nav.Link
                className="navbar-primary__nav-link"
                href={t('application_link')}
                target="_blank"
                style={{fontFamily: i18n.language === 'en_HK' ? '' : 'monospace'}}
                onClick={() => {
                  conversion();
                  triggerEvent({
                    event:
                      {
                        event_action: "CATHAY_HACKATHON_HEADER::APPLY_NOW_BTN"
                      }
                  });
                }}
              >
                {t('application_button_text')}
              </Nav.Link> )
              : null
          }
        </div>
      </div>
    </Navbar>
  );
}

function SideMenu({ showMenu, closeSideMenuHandler, pathName, headerTranslations }: SideMenuProps) {
  const { i18n, t } = useTranslation('header');
  const { portal } = useFeatureToggles();

  return (
    <AnimatePresence>
      {showMenu && (
        <motion.div
          initial={{ x: '-100%' }}
          animate={{ x: '0%' }}
          exit={{ x: '-100%' }}
          transition={{
            duration: 0.3,
            ease: 'easeInOut',
          }}
          className="sidebar"
        >
          <div className="sidebar__header">
            <img
              className="sidebar__header__image"
              src="https://www.cathaypacific.com/content/dam/header-footer-lrp/menu_home.originalimage.jpg"
              alt="Header image"
            />
            <button
              onClick={closeSideMenuHandler}
              className="sidebar__header__close close-button btn-close shadow-none"
            >
              <span className="visually-hidden">close</span>
            </button>
          </div>
          <SideLinks
            closeSideMenuHandler={closeSideMenuHandler}
            pathName={pathName}
            headerTranslations={headerTranslations}
          />
          {
            portal ? (
              <Nav.Link
                className="sidebar__header__portal-button"
                id="portal"
                as={Link}
                to={t('portal_link')}
                onClick={closeSideMenuHandler}
              >
                <img className="sidebar__header__portal-icon" src={PortalIcon} alt="Portal Icon"/>
                {t('portal_text')}
              </Nav.Link>
            ) : null
          }
          <Nav.Link
            className="sidebar__header__recap-button"
            id="recap"
            as={Link}
            to={'/' + i18n.language + t('recap_link') + '?cxsource=HACKATHON_HISTORY_LINK'}
            onClick={closeSideMenuHandler}
          >
            <img className="sidebar__header__recap-icon" src={RecapIcon} alt={t('recap_icon_alt_text')}></img>
            {t('recap_text')}
          </Nav.Link>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

function SideLinks({ closeSideMenuHandler, pathName, headerTranslations }: SideLinksProps) {
  // const { i18n } = useTranslation();
  const { t } = useTranslation('header');

  return (
    <div className="sidebar__content">
      {headerTranslations?.map((value, index) => {
        return (
          <Nav.Link
            key={index}
            className={`sidebar__nav-link ${pathName === `#${t('navigation_link_' + index)}` ? 'sidebar__nav-link--current' : ''}`}
            as={Link}
            smooth to={t('navigation_link_' + index)}
            scroll={scrollWithOffset}
            onClick={() => {
              linkEvent(tabName[index]+'_TAB');
              closeSideMenuHandler();
            }}
          >
            {t('navigation_title_' + index)}
          </Nav.Link>
        );
      })}
    </div>
  );
}
export default ResponsiveNavbar;
