import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getHistoryHighlightsTranslations } from '../../../services/HistoryPageServices';
import { axiosNoAuth } from '../../../services/axios';
import { useState } from 'react';
import { Container } from 'react-bootstrap';
import parse from 'html-react-parser';
import LazyVideo from '../../common/LazyVideo';
import videoPoster from '../../../assets/images/hackathon-video-poster.png';
import playIcon from '../../../assets/images/icon/icon-play.svg';
import arrowIcon from '../../../assets/images/icon/carouselbutton_green.svg';

interface HistoryCardProps {
  index: number;
}

function HistoryCard({index}: HistoryCardProps) {
  const {t} = useTranslation('recap_highlights');
  return(
    <div className="history-card">
      <img className="history-card__image"
        src={t(`highlight_card_image_${index}`)}
        alt={t(`highlight_card_image_alt_text_${index}`)}/>
      <div className="history-card__textbox">
        <div className="history-card__section_title">{t(`highlight_card_section_title_${index}`)}</div>
        <div className="history-card__title">{t(`highlight_card_title_${index}`)}</div>
      </div>
    </div>
  );
}

function HistoryHighlights() {
  const { t } = useTranslation('recap_highlights');
  const { data: highlightsTrans, isLoading } = useQuery({
    queryKey: ['recap_highlights'],
    queryFn: () => getHistoryHighlightsTranslations(axiosNoAuth),
    staleTime: Infinity
  });

  const [showVideoControls, setShowVideoControls] = useState(false);
  const [hideVideoIcon, setHideVideoIcon] = useState(false);

  const playVideo = () => {
    const videoEl: HTMLVideoElement | null = document.getElementById('hackathon-video') as HTMLVideoElement;
    if (videoEl) {
      setShowVideoControls(true);
      setHideVideoIcon(true);
      videoEl.play();
    }
  };

  if (!highlightsTrans || isLoading) return null;

  return(
    <section id='history-highlight' className='history-highlights'>
      <Container className='history-highlight-section__container'>
        <div className="history-highlight-container">
          <div className="highlight_container">
            <div className='name'>{t('highlight_section_name')}</div>
            <div className="title">{t('highlight_title')}</div>
            <div className="description">{parse(t('highlight_description'))}</div>
            <a
              className='link'
              href={t('highlight_read_more_link')}
            >
              {t('highlight_read_more_text')}
              <img
                src={arrowIcon}
                className='arrow'
                alt='arrow icon'
              />
            </a>
          </div>
          <div className='video_container'>
            <LazyVideo
              src={t('highlight_video_link')}
              preload="none"
              width="100%"
              poster={videoPoster}
              controls={showVideoControls}
              videoId='hackathon-video'
            />
            <img
              src={playIcon}
              alt='Play Icon'
              onClick={() => playVideo()}
              hidden={hideVideoIcon}
              className='overlay_icon'
            />
          </div>
        </div>
        <div className="prize_container">
          <div className="prize_cards_container">
            {
              highlightsTrans?.map((index) => {
                return(
                  <HistoryCard key={index} index={index}/>
                );
              })
            }
          </div>
        </div>
      </Container>
    </section>
  );
}

export default HistoryHighlights;