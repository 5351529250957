import './App.scss';
import { BrowserRouter as Router, Routes, Route, Outlet, useNavigate,} from 'react-router-dom';
import AboutPage from './components/pages/about';
import ResponsiveNavbar from './components/layouts/ResponsiveNavbar';
import SponsorSection from './components/layouts/SponsorSection';
import ResponsiveFooter from './components/layouts/ResponsiveFooter';
import HistoryPage from './components/pages/history';
import ScrollToTop from './components/layouts/ScrollToTop';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL ?? '.'}>
      <ScrollToTop />
      <div className="brand-hub-page">
        <Pages />
      </div>
    </Router>
  );
}

const LanguageWrapper = () => {
  const { lang } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (lang !== undefined && ['en_HK', 'zh_HK', 'sc_CN'].includes(lang)) {
      i18n.changeLanguage(lang);
      const language = lang === 'sc_CN' ? 'zh-CN' : lang.split('_')[0];
      document.documentElement.setAttribute('lang', language);
    } else {
      navigate('/en_HK/');
      i18n.changeLanguage('en_HK');
      document.documentElement.setAttribute('lang', 'en');
    }
  }, [lang]);

  return <Outlet />;
};

const NavigateToLanding = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    /*
    Chrome settings mapping ref:
      Chinese (Simplified): zh-CN
      Chinese (Traditional): zh-TW
      Chinese: zh
      Chinese (Hong Kong): zh-HK
    */

    if (i18n.language === 'zh-CN') navigate('/sc_CN/');
    else if (i18n.language === 'zh-HK' || i18n.language === 'zh-TW')
      navigate('/zh_HK/');
    else navigate('/en_HK/');
  });

  return <></>;
};

function Pages() {
  const location = useLocation();
  const { t, i18n } = useTranslation('seo');

  // TODO: change to backend next year
  i18n.addResource('en_HK', 'seo', 'page_title', 'Cathay Hackathon');
  i18n.addResource(
    'zh_HK',
    'seo',
    'page_title',
    '國泰科創馬拉松 | Cathay Hackathon'
  );
  i18n.addResource(
    'sc_CN',
    'seo',
    'page_title',
    '国泰科创马拉松 | Cathay Hackathon'
  );
  i18n.addResource(
    'en_HK',
    'seo',
    'page_description',
    "Eager to ideate, create, and innovate the next big thing in aviation? Look no further than the Cathay Hackathon, Cathay's flagship event and competition"
  );
  i18n.addResource(
    'zh_HK',
    'seo',
    'page_description',
    '國泰科創馬拉松是國泰年度的科技比賽。熱愛科技與航空的你，渴望構思、創新和開發下一個影響航空業的重大項目嗎？請立即報名！'
  );
  i18n.addResource(
    'sc_CN',
    'seo',
    'page_description',
    '国泰科创马拉松是国泰年度的科技比赛。热爱科技与航空的你，是否渴望构思、创新和开发航空业的下一个重大项目？请立即报名！'
  );
  i18n.addResource(
    'en_HK',
    'seo',
    'page_keywords',
    'cathay, hackathon, cathay pacific, aviation, technology, competition'
  );
  i18n.addResource(
    'zh_HK',
    'seo',
    'page_keywords',
    '國泰,科創馬拉松, 駭客松, 黑客馬拉松, 國泰航空, 科技, 航空, 比賽'
  );
  i18n.addResource(
    'sc_CN',
    'seo',
    'page_keywords',
    '国泰,科创马拉松, 骇客松, 黑客马拉松, 国泰航空, 科技, 航空, 比赛'
  );

  return (
    <>
      <Helmet>
        <title>{t('page_title')}</title>
        <meta name="description" content={t('page_description')} />
        <meta name="keywords" content={t('page_keywords')} />
        <meta property="og:title" content={t('page_title')} />
        <meta property="og:description" content={t('page_description')} />
        <meta itemProp="name" content={t('page_title')} />
        <meta itemProp="description" content={t('page_description')} />
        <meta property="og:locale" content={i18n.language} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={t('page_title')} />
        <meta
          property="og:image"
          content="https://www.cathaypacific.com/content/dam/cx/designs/responsive/images/Social-Sharing/share-facebook-ENG.originalimage.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.cathaypacific.com/content/dam/cx/designs/responsive/images/Social-Sharing/share-facebook-ENG.originalimage.jpg"
        />
        <meta property="og:image:height" content="850" />
        <meta property="og:image:width" content="1700" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={t('page_title')} />
        <meta name="twitter:description" content={t('page_description')} />
        <meta
          name="twitter:image"
          content="https://www.cathaypacific.com/content/dam/cx/designs/responsive/images/Social-Sharing/share-facebook-ENG.originalimage.jpg"
        />
        <link
          rel="canonical"
          href={`https://hackathon.cathaypacific.com/${i18n.language}/`}
        />
        <link
          rel="alternate"
          hrefLang="en-hk"
          href="https://www.cathaypacific.com/cx/en_HK.html"
        />
        <link
          rel="alternate"
          hrefLang="zh-Hant-hk"
          href="https://hackathon.cathaypacific.com/zh_HK"
        />
        <link
          rel="alternate"
          hrefLang="zh-Hans-cn"
          href="https://hackathon.cathaypacific.com/sc_CN"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href=" https://hackathon.cathaypacific.com/en_HK"
        />
      </Helmet>
      <ResponsiveNavbar pathName={location.hash} />
      <Routes>
        <Route path="/" element={<NavigateToLanding />} />
        <Route path=":lang/*" element={<LanguageWrapper />}>
          <Route path="" element={<>
            <AboutPage />
            <SponsorSection />
          </>} />
          <Route path="history" element={<HistoryPage />} />
          <Route path="*" element={<NavigateToLanding />} />
        </Route>
      </Routes>
      <ResponsiveFooter />
    </>
  );
}

export default App;
