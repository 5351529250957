import { AxiosInstance } from 'axios';
import { i18nAppend } from '../helpers/i18nAppend';
import { RecapTranslationsType } from '../types/RecapTranslationsType';

let recapDataCache: Promise<RecapTranslationsType[]> | null = null;

const fetchRecapData = async (axios: AxiosInstance) => {
  if (!recapDataCache) {
    recapDataCache = axios.get('/hackathon-cms/v1/marksite/recap').then(response => response.data);
  }
  return recapDataCache;
};

export const getHistoryBannerTranslations = async (axios: AxiosInstance) => {
  const bannerTranslations: RecapTranslationsType[] = await fetchRecapData(axios);

  const filteredBannerTranslations = bannerTranslations.filter((obj) => {
    return obj.id != null;
  });

  const bannerResponseStructure = {
    cover_description: '',
    cover_image: '',
    cover_image_alt_text: '',
    cover_section_name: '',
    cover_title: '',
  };

  i18nAppend(filteredBannerTranslations, 'recap_banner', bannerResponseStructure);

  return filteredBannerTranslations;
};

export const getHistoryHighlightsTranslations = async (axios: AxiosInstance) => {
  const highlightsTranslations: RecapTranslationsType[] = await fetchRecapData(axios);

  const filteredHighlightsTranslations = highlightsTranslations.filter((obj) => {
    return obj.id != null;
  });

  const highlightsResponseStructure = {
    highlight_card: [{
      description: '',
      image: '',
      image_alt_text: '',
      title: '',
      section_title: '',
    }],
    highlight_description: '',
    highlight_read_more_link: '',
    highlight_read_more_text: '',
    highlight_section_name: '',
    highlight_title: '',
    highlight_video_link: '',
  };

  i18nAppend(filteredHighlightsTranslations, 'recap_highlights', highlightsResponseStructure);

  return filteredHighlightsTranslations[0].highlight_card.map((value, index) => {
    return index;
  });
};

export const getHistoryLegacyTranslations = async (axios: AxiosInstance) => {
  const legacyTranslations: RecapTranslationsType[] = await fetchRecapData(axios);

  const filteredLegacyTranslations = legacyTranslations.filter((obj) => {
    return obj.id != null;
  });

  const legacyResponseStructure = {
    legacy_card: [{
      legacy_card_link: '',
      legacy_card_image: '',
      legacy_card_image_alt_text: '',
      legacy_card_title: '',
    }],
    legacy_section_name: '',
    legacy_title: '',
  };

  i18nAppend(filteredLegacyTranslations, 'recap_legacy', legacyResponseStructure);

  return filteredLegacyTranslations[0].legacy_card.map((card, index) => {
    return index;
  });
};