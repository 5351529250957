import { motion } from 'framer-motion';
import { initialGoDown, initialGoUp, transition, whileInView } from '../../../helpers/animations';
import { JourneyCardType } from '../../../types/JourneyType';
import { useTranslation } from 'react-i18next';
import { getJourneyTranslations } from '../../../services/HomePageServices';
import { axiosNoAuth } from '../../../services/axios';
import { useQuery } from 'react-query';
import calendarLogo from '../../../assets/images/icon/icon-calendar.svg';
import locationLogo from '../../../assets/images/icon/icon-location.svg';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Container } from 'react-bootstrap';

function Journey() {
  const { t } = useTranslation('journey');
  const { data: journeyTranslations, isLoading } = useQuery({
    queryKey: ['journey'],
    queryFn: () => getJourneyTranslations(axiosNoAuth),
    staleTime: Infinity
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isLoading || !journeyTranslations) {
    return null;
  }

  function getCard(i: number, arrayIndex: number) {
    if (i >= (journeyTranslations? journeyTranslations.length : 0)) {
      return <div></div>;
    }
    const card = {
      img: t(`timeline_card_image_link_${i}`),
      title: t(`timeline_card_title_${i}`),
      text: t(`timeline_card_description_${i}`),
      time_range: t(`timeline_card_time_range_${i}`),
      venue: t(`timeline_card_venue_${i}`),
    };
    return (
      <JourneyCard key={arrayIndex} card={card} index={arrayIndex}/>
    );
  }

  const journeyHeader = (
    <div className="journey__col">
      <motion.div
        initial={initialGoDown}
        whileInView={whileInView}
        transition={transition}
        className="journey__heading"
      >
        {t('timeline_section_name')}
      </motion.div>
      <motion.h2
        initial={initialGoDown}
        whileInView={whileInView}
        transition={transition}
        className="journey__title"
      >
        {t('timeline_title')}
      </motion.h2>
      <motion.div
        initial={initialGoDown}
        whileInView={whileInView}
        transition={transition}
        className="journey__text"
      >
        {t('timeline_description')}
      </motion.div>
    </div>
  );

  // it's an ugly solution, but blame MarkComms (thx)
  const masonryElements = [];
  for (let i = 0; i <= journeyTranslations.length; i++) {
    if ((i === 1 && !isMobile) || (i === 0 && isMobile)) {
      masonryElements.push(journeyHeader);
      continue;
    }
    if (i % 2 === 0 && !isMobile) {
      masonryElements.push(getCard(i, i));
      continue;
    }
    if (isMobile) {
      masonryElements.push(getCard(i - 1, i - 1));
      continue;
    }
    if (i % 2 !== 0) {
      masonryElements.push(getCard(i - 2, i));
      continue;
    }
  }

  return (
    <section id="journey" className="journey">
      <Container className="journey__container">
        <ResponsiveMasonry
          columnsCountBreakPoints={{
            0: 1,
            1024: 2
          }}
        >
          <Masonry gutter="20px">
            {masonryElements}
          </Masonry>
        </ResponsiveMasonry>
      </Container>
    </section>
  );
}

function JourneyCard({ card, index }: JourneyCardType) {
  return (
    <div className='journey__card'>
      <motion.div
        initial={initialGoUp}
        whileInView={whileInView}
        transition={transition}
      >
        <img
          className="journey__card__img"
          src={`${card.img}`}
          alt="Innovation Fly"
        />
        <div className={`journey__card-textbox ${(index % 2 === 1) ? 'journey__card-textbox--right' : '' /* this is because we added the journey text */}`}>
          <h3 className="journey__card-textbox__title">{card.title}</h3>
          <div className="journey__card-textbox__text">{parse(card.text)}</div>
          <div className="journey__card-textbox__info-box">
            <div className="journey__card-textbox__info-box__row">
              <img src={calendarLogo} alt='calender logo'/>
              <div className="journey__card-textbox__detail">{card.time_range}</div>
            </div>
            <div className="journey__card-textbox__info-box__row">
              <img src={locationLogo} alt='location logo'/>
              <div className="journey__card-textbox__detail">{card.venue}</div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Journey;
