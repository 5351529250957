import { motion } from 'framer-motion';
import { initialGoUp, initialGoDown, whileInView, transition } from '../../../helpers/animations';
import { PrizesAndAwardsType } from '../../../types/PrizesAndAwardsType';
import useScrollPosition from '../../../helpers/useScrollPosition';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { axiosNoAuth } from '../../../services/axios';
import { getPrizesAndAwardsTranslations } from '../../../services/HomePageServices';
import { getHeaderTranslations } from '../../../services/NavigationItemServices';
import parse from 'html-react-parser';
import { Container, Nav } from 'react-bootstrap';
import { useFeatureToggles } from '../../../contexts/FeatureTogglesContext';
import { conversion, triggerEvent } from '../../../helpers/cxDataLayer';

interface CardIndex {
  idx: number
}
function TopWinnersCard({idx}:CardIndex) {
  const {t} = useTranslation('prizes_and_awards');
  return (
    <div className='prizes-and-awards__card_container'>
      <div
        key={idx}
        className='prizes-and-awards__card_container__icon_col'
      >
        <img
          alt={t(`top_winners_card_icon_alt_text_${idx}`)}
          src={t(`top_winners_card_icon_link_${idx}`)}
          className='prizes-and-awards__card_container__icon_col__icon'
        />
      </div>
      <div className='prizes-and-awards__card_container__content_col'>
        <div
          key={idx}
          className='prizes-and-awards__card_container__content_col__title'
        >
          {t(`top_winners_card_title_${idx}`)}
        </div>
        <div key={idx}>
          {parse(t(`top_winners_card_description_${idx}`))}
        </div>
      </div>
    </div>
  );
}
function OtherPrizesCard({idx}:CardIndex) {
  const {t} = useTranslation('prizes_and_awards');

  return (
    <div className='prizes-and-awards__card_container'>
      <div
        key={idx}
        className='prizes-and-awards__card_container__icon_col'
      >
        <img
          alt={t(`other_prizes_card_icon_alt_text_${idx}`)}
          src={t(`other_prizes_card_icon_link_${idx}`)}
          className='prizes-and-awards__card_container__icon_col__icon'
        />
      </div>
      <div className='prizes-and-awards__card_container__content_col'>
        <div
          key={idx}
          className='prizes-and-awards__card_container__content_col__title'
        >
          {t(`other_prizes_card_title_${idx}`)}
        </div>
        <div key={idx}>
          {parse(t(`other_prizes_card_description_${idx}`))}
        </div>
      </div>
    </div>
  );
}

function PrizesAndAwards({ animationTiming }: PrizesAndAwardsType) {
  const {isCurrentPosition, ref} = useScrollPosition(animationTiming);

  const {t: tPrizeAndAward} = useTranslation('prizes_and_awards');
  const {data: prizesAndAwardsDataIdx, isLoading: isLoadingPrizeAndAwardTranslation } = useQuery({
    queryKey: ['prizes_and_awards'],
    queryFn: () => getPrizesAndAwardsTranslations(axiosNoAuth),
    staleTime: Infinity
  });

  const { t: tHeader } = useTranslation('header');
  const { isLoading: isLoadingHeaderTranslation} = useQuery({
    queryKey: ['header'],
    queryFn: () => getHeaderTranslations(axiosNoAuth),
    staleTime: Infinity
  });

  const { application } = useFeatureToggles();

  if (!prizesAndAwardsDataIdx || isLoadingPrizeAndAwardTranslation) return null;

  return (
    <section
      id="prizes-and-awards"
      ref={ref}
      className="prizes-and-awards"
    >
      <Container className='content_box'>
        <motion.div
          initial={initialGoDown}
          whileInView={whileInView}
          transition={transition}
        >
          <div className="section_content">{tPrizeAndAward('prizes_section_name')}</div>
        </motion.div>

        <motion.div
          initial={initialGoUp}
          whileInView={whileInView}
          transition={transition}
        >
          <div className="title_content">
            {tPrizeAndAward('top_winners_title')}
          </div>

          <div className="prizes-and-awards-container row">
            {
              prizesAndAwardsDataIdx?.top_winners_card_idx.map((idx) => (
                <div key={idx} className="col col-xl-4">
                  <TopWinnersCard idx={idx}/>
                </div>
              ))
            }
          </div>

          <div className="title_content">
            {tPrizeAndAward('other_prizes_title')}
          </div>

          <div className="prizes-and-awards-container row">
            {
              prizesAndAwardsDataIdx?.other_prizes_card_idx.map((idx) => (
                <div key={idx} className="col col-xl-3">
                  <OtherPrizesCard idx={idx}/>
                </div>
              ))
            }
          </div>

          <div className='apply-now-container'>
            {
              application ? (
                <>
                  <div>{parse(tHeader('application_remarks'))}</div>
                  <Nav.Link
                    className="apply-now-container__apply-button"
                    href={tHeader('application_link')}
                    target="_blank"
                    onClick={() => {
                      conversion();
                      triggerEvent({
                        event:
                          {
                            event_action: "CATHAY_HACKATHON_PRIZES_AND_AWARDS::APPLY_NOW_BTN"
                          }
                      });
                    }}
                  >
                    {tHeader('application_button_text')}
                  </Nav.Link>
                </>
              ) : null
            }

          </div>
        </motion.div>
      </Container>
    </section>
  );
}

export default PrizesAndAwards;
