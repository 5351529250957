import { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import checkScrollPosition from './checkScrollPosition';
import { getFaqTranslations } from '../services/HomePageServices';
import { axiosNoAuth } from '../services/axios';
import { useQuery } from 'react-query';

const useScrollPosition = (animationTiming: number) => {
  const [isCurrentPosition, setIsCurrentPosition] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  const { data: faqTranslations, isLoading } = useQuery({
    queryKey: ['faq-translations'],
    queryFn: () => getFaqTranslations(axiosNoAuth),
    staleTime: Infinity
  });

  const handleScroll = _.debounce(() => {
    if (ref.current) {
      checkScrollPosition(
        ref.current,
        animationTiming,
        () => {
          setIsCurrentPosition(true);
        },
        () => {
          setIsCurrentPosition(false);
        }
      );
    }
  }, 25);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { isCurrentPosition,
    ref,
    faqTranslations
  };
};

export default useScrollPosition;