import i18next from 'i18next';

/*
INPUT PARAMS:
translationData: Array of objects
page: String
keys: Object

Reference: See /services/FaqServices.tsx
*/
export const i18nAppend =  (translationData, page, keys = {}) => {

  const i18NextResources = [{}, {}, {}];

  translationData.forEach((translations, i) => {
    for (const key in keys) {
      if (Array.isArray(translations[key])) {
        translations[key].forEach((qnaObj, j) => {
          for (const subKey in qnaObj) {
            const i18NextResourcesKey = `${key}_${subKey}_${j}`;
            i18NextResources[i][i18NextResourcesKey] = qnaObj[subKey];
          }
        });
      } else {
        i18NextResources[i][key] = translations[key];
      }
    }
  });

  const languages = ['en_HK', 'sc_CN', 'zh_HK'];
  languages.forEach((value, index) => {
    i18next.addResourceBundle(value, page, i18NextResources[index], true, true);
  });
};