import { useState, Fragment, FC } from 'react';
import parse from 'html-react-parser';
import { axiosNoAuth } from '../../../services/axios';
import useScrollPosition from '../../../helpers/useScrollPosition';
import { useTranslation } from 'react-i18next';
import { getFaqTranslations } from '../../../services/HomePageServices';
import { useQuery } from 'react-query';
import { getHeaderTranslations } from '../../../services/NavigationItemServices';
import { Nav } from 'react-bootstrap';
import { useFeatureToggles } from '../../../contexts/FeatureTogglesContext';
import { conversion, triggerEvent } from '../../../helpers/cxDataLayer';
interface FAQQuestionProps {
  index: number
}

const FAQQuestion: FC<FAQQuestionProps> = ({ index }) => {
  const [expand, setExpand] = useState(false);
  const { t } = useTranslation('faq');

  return (
    <div className="question-row">
      <div className="card m-0 p-2">
        <div
          className={`${expand ? 'activestate' : ''}`}
          onClick={() => {
            triggerEvent({
              event:
                {
                  event_action: "CATHAY_HACKATHON::FAQ_PANEL::" + (expand ? "COLLAPSE::" : "EXPAND::") + (index+1)
                }
            });
            setExpand(!expand);
          }}
        >
          <div className="row">
            <hr className="top-divider" />
            <div className="col col-1">
              <svg
                width="20"
                height="14"
                viewBox="0 0 20 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`${expand ? 'flip' : ''}`}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.5658 6.82135C16.3516 5.85886 17.1049 4.86511 17.8562 3.8638C18.5891 2.84742 19.3264 1.83535 20 0.751061C19.0063 1.48399 18.0782 2.28911 17.1449 3.09102C16.2276 3.90801 15.3168 4.73146 14.4341 5.58831C13.5341 6.42148 12.6849 7.31713 11.8139 8.18263C11.1974 8.80667 10.6003 9.45336 9.99998 10.0958C9.39961 9.45336 8.80361 8.80667 8.18926 8.17938C7.31409 7.315 6.46908 6.42038 5.56802 5.58509C4.68529 4.72821 3.77346 3.90479 2.85729 3.08777C1.9239 2.28911 0.993748 1.48292 0 0.75C0.671464 1.83536 1.4098 2.84851 2.1427 3.86597C2.89285 4.86725 3.64624 5.86207 4.43199 6.82349C5.19722 7.80647 6.01746 8.72907 6.81073 9.68186C7.61692 10.6228 8.44147 11.54 9.27246 12.4519L10.001 13.2494L10.7275 12.4519C11.5575 11.5379 12.383 10.6206 13.1871 9.67969C13.9815 8.72907 14.7995 7.80321 15.5658 6.82135Z"
                  fill="#0F748F"
                />
              </svg>
            </div>
            <div className="col">
              <div className="card-header py-0">{t(`faq_card_questions_${index}`)}</div>
            </div>
          </div>
          <div className={`answer ${expand ? 'show' : ''}`} role="tabpanel">
            <div className="row">
              <div className="col col-1"></div>
              <div className="col">
                <div className="card-body py-0">{parse(t(`faq_card_answer_${index}`))}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface FAQSectionProps {
  animationTiming: number;
}

const FAQSection: FC<FAQSectionProps> = ({ animationTiming }) => {
  const { isCurrentPosition, ref } = useScrollPosition(animationTiming);
  const { t } = useTranslation('faq');

  const { data: faqData, isLoading } = useQuery<number[]>({
    queryKey: ['faq'],
    queryFn: () => getFaqTranslations(axiosNoAuth),
    staleTime: Infinity
  });

  const { t: tHeader } = useTranslation('header');
  const { isLoading: isLoadingHeaderTranslation} = useQuery({
    queryKey: ['header'],
    queryFn: () => getHeaderTranslations(axiosNoAuth),
    staleTime: Infinity
  });

  const { application } = useFeatureToggles();


  if (!faqData || isLoading ) return null;

  return (
    <section id="faq" ref={ref} className="faq-section container-fluid">
      <div className="basic-section-row">
        <div className={`section-content ${isCurrentPosition ? 'active' : ''}`}>
          <div className="faq__container">
            <p className="section-initial">{t('faq_section_name')}</p>
            <p className="section-title">{t('faq_title')}</p>
            {faqData.map((faqItem, index) => (
              <Fragment key={index}>
                <FAQQuestion index={faqItem}/>
              </Fragment>
            ))}
            <hr className="top-divider" />
          </div>
        </div>
        <div className='apply-now-container'>
          {
            application ? (
              <>
                <div>{parse(tHeader('application_remarks'))}</div>
                <Nav.Link
                  className="apply-now-container__apply-button"
                  href={tHeader('application_link')}
                  target="_blank"
                  onClick={() => {
                    conversion();
                    triggerEvent({
                      event:
                        {
                          event_action: "CATHAY_HACKATHON_FAQ::APPLY_NOW_BTN"
                        }
                    });
                  }}
                >
                  {tHeader('application_button_text')}
                </Nav.Link>
              </>
            ) : null
          }
        </div>
      </div>
    </section>
  );
};

export default FAQSection;