import PropTypes from 'prop-types';
import { ResponseFooterType } from '../../types/ResponseFooterType';
import { getFooterTranslations } from '../../services/NavigationItemServices';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { axiosNoAuth } from '../../services/axios';
import parse from 'html-react-parser';
import LanguageIcon from '../../assets/images/LanguageIconWhite.svg';
import LinkIcon from '../../assets/images/icon/DocLinkIconWhite.svg';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { updatePageLang } from '../../helpers/cxDataLayer';

function ResponsiveFooter() {
  const { t, i18n } = useTranslation('footer');
  const location = useLocation();
  const navigate = useNavigate();

  const [languages, setLanguages] = useState(["English:en_HK", "繁體中文:zh_HK", "简体中文:sc_CN"]);

  const { data: footerTranslations, isLoading } = useQuery({
    queryKey: ['footer'],
    queryFn: () => getFooterTranslations(axiosNoAuth),
    staleTime: Infinity
  });

  if (isLoading || !footerTranslations) return null;

  const onClick = (lang: string) => {
    const currentPage = location.pathname.slice(7);
    i18n.changeLanguage(lang);
    navigate(`/${i18n.language}/${currentPage}`);
    updatePageLang({
      language: lang.split('_')[0],
      country: lang.split('_')[1],
      locale: lang
    });
  };



  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__first-row">
          <p className="footer__text">
            <div className="footer__link-items">
              <a className='link' href={t('contact_us_link')}>
                {t('contact_us_text')}
                <img src={LinkIcon} className='link' alt='link icon'/>
              </a>
            </div>
            <div className="footer__link-items">
              <a className='link' href={t('terms_and_conditions_link')} target='_blank' rel="noreferrer">
                {t('terms_and_conditions_text')}
                <img src={LinkIcon} className='link' alt='link icon'/>
              </a>
            </div>
            <div className="footer__link-items">
              <a className='link' href={t('cookie_policy_link')} target='_blank' rel="noreferrer">
                {t('cookie_policy_text')}
                <img src={LinkIcon} className='link' alt='link icon'/>
              </a>
            </div>
          </p>
          <hr className="footer__divider" />
          <div className='footer__lang'>
            <img className='footer__lang__icon' src={LanguageIcon} alt="Language Icon"></img>
            {languages.map((value) => {
              const [label, lang] = value.split(":");
              if (i18n.language !== lang) {
                return (
                  <button key={lang} onClick={() => onClick(lang)}>
                    <p>{label}</p>
                  </button>
                );
              }
            })}
          </div>
        </div>
        <hr className="footer__divider" />
        <div className='footer__bottom'>
          <div className="footer__bottom__copyright">
            {parse(t('copyright'))}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default ResponsiveFooter;

SocialIcon.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.string,
};

function SocialIcon({ name, url, icon }: ResponseFooterType) {
  return (
    <a
      className="footer__social-icon"
      href={url}
      rel="noreferrer"
      target="_blank"
    >
      <span className="visually-hidden">{name}</span>
      <img alt={name} src={icon} aria-hidden="true" />
    </a>
  );
}