declare const window: { env: any };

const config = Object.freeze({
  // TODO: REPLACE YOUR ENVIRONMENT VARIABLES HERE
  DEMO_ENV_VAR: window.env?.DEMO_ENV_VAR || 'React template - default',
  ENV: window.env?.ENV,
  BASE_API_URL: window.env?.BASE_API_URL,
});

export default config;
