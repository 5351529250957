import { useTranslation } from 'react-i18next';
import LanguageIcon from '../../assets/images/LanguageIcon.svg';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CloseButton } from 'react-bootstrap';
import { updatePageLang } from '../../helpers/cxDataLayer';

export function LanguageSelectorBanner() {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [languages, setLanguages] = useState(['English:en_HK', '繁體中文:zh_HK', '简体中文:sc_CN']);
  const [bannerHidden, setBannerHidden] = useState(false);

  const onClick = (locale: string) => {
    const currentPage = location.pathname.slice(7);
    i18n.changeLanguage(locale);
    navigate(`/${i18n.language}/${currentPage}`);
    updatePageLang({
      language: locale.split('_')[0],
      country: locale.split('_')[1],
      locale: locale
    });
  };

  return (
    <>
      {
        !bannerHidden &&
                  <div className='wrapper'>
                    <div className='language-banner'>
                      <div className='language-banner__left-grp'>
                        <div className='language-banner__left-grp__top'>
                          <img src={LanguageIcon} alt="Language Icon"></img>
                          <p>Choose your website language</p>
                        </div>
                        <div className='language-banner__left-grp__buttons'>
                          <p> 請選擇網站語言:</p>
                          <div className='language-selector'>
                            {languages.map((value) => {
                              const [label, lang] = value.split(':');
                              if (i18n.language !== lang) {
                                return (
                                  <p onClick={() => onClick(lang)} key={lang}>{label}</p>
                                );
                              }
                              return null; // Add a return statement at the end of the arrow function
                            })}
                          </div>
                        </div>
                      </div>
                      <div className='language-banner__right-grp'>
                        <CloseButton className='language-banner__right-grp__close-button' onClick={() => setBannerHidden(true)} />
                      </div>
                    </div>
                  </div>
      }
    </>
  );
}