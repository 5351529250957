import { useEffect } from 'react';
import HistoryBanner from './HistoryBanner';
import HistoryHighlights from './HistoryHighlights';
import HistoryLegacy from './HistoryLegacy';
import { updatePageview } from '../../../helpers/cxDataLayer';


function HistoryPage() {
  const lang = location.pathname.slice(1,6);
  useEffect(() => {
    updatePageview({
      name: 'HACKATHON/HISTORY',
      language: lang.split('_')[0],
      country: lang.split('_')[1],
      locale: lang
    });
  }, []);

  return (
    <div>
      {<HistoryBanner />}
      {<HistoryHighlights />}
      {<HistoryLegacy />}
    </div>
  );
}

export default HistoryPage;
