import ImageBanner from './ImageBanner';
import DetailSection from './EventDetailSection';
import Journey from './Journey';
import AboutCathayHackathon from './AboutCathayHackathon';
import HighlightsSection from './HighlightsSection';
import PrizesAndAwards from './PrizesAndAwards';
import FAQSection from './FAQSection';
import { updatePageview } from '../../../helpers/cxDataLayer';
import { useEffect } from 'react';

function AboutPage() {
  const TRANSLATE_LIMIT = -250;
  const lang = location.pathname.slice(1,6);
  useEffect(() => {
    updatePageview({
      name: 'HACKATHON/HOMEPAGE',
      language: lang.split('_')[0],
      country: lang.split('_')[1],
      locale: lang
    });
  }, []);

  return (
    <div id="about-session">
      <ImageBanner />
      <DetailSection
        contentId={1}
        animationTiming={-100}
        amplifier={0.05}
        translateLimit={TRANSLATE_LIMIT}
      />
      <HighlightsSection />
      <Journey />
      <PrizesAndAwards
        contentId={0}
        animationTiming={-500}
        amplifier={0.03}
        translateLimit={TRANSLATE_LIMIT}
        backgroundColor="#FFFFFF"
      />
      <AboutCathayHackathon />
      <FAQSection animationTiming={-500} />
    </div>
  );
}

export default AboutPage;
