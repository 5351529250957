import React from 'react';
import LazyLoad from 'vanilla-lazyload';

// Configuration for vanilla-lazyload
const lazyloadConfig = {
  elements_selector: '.lazy-video',
};

// Initialize LazyLoad instance if it doesn't exist
if (!(document as any).lazyLoadInstance) {
  (document as any).lazyLoadInstance = new LazyLoad(lazyloadConfig);
}

interface LazyVideoProps {
  src: string;
  width: string;
  height?: string;
  poster: string;
  controls?: boolean;
  preload?: string;
  videoId?: string;
  className?: string;
}

class LazyVideo extends React.Component<LazyVideoProps> {
  componentDidMount() {
    (document as any).lazyLoadInstance.update();
  }

  componentDidUpdate() {
    (document as any).lazyLoadInstance.update();
  }

  render() {
    const {
      src,
      width,
      height,
      poster,
      controls = true,
      preload = 'none',
      videoId
    } = this.props;
    return (
      <video
        // className="lazy-video"
        width={width}
        height={height}
        controls={controls}
        preload={preload}
        poster={poster}
        data-src={src}
        id={videoId}
        // style={{ objectFit: 'cover',
        // display: 'block',
        // height: '100%',
        // width: '100%',
        //  }}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }
}

export default LazyVideo;
