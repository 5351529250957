import { motion } from 'framer-motion';
import { initialGoDown, whileInView, transition, initialGoUp } from '../../helpers/animations';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { axiosNoAuth } from '../../services/axios';
import { getSponsorTranslations } from '../../services/HomePageServices';

function SponsorSection() {
  const { t } = useTranslation('sponsor');
  const { data: sponsorTrans, isLoading } = useQuery({
    queryKey: ['sponsor'],
    queryFn: () => getSponsorTranslations(axiosNoAuth),
    staleTime: Infinity
  });

  if (isLoading || !sponsorTrans) return null;

  return (
    <section className="sponsor-section">
      <div className="sponsor-section__container">
        <motion.h2
          initial={initialGoDown}
          whileInView={whileInView}
          transition={transition}
          className="sponsor-section__title"
        >
          {t('sponsor_title')}
        </motion.h2>
        <motion.div
          initial={initialGoUp}
          whileInView={whileInView}
          transition={transition}
          className="sponsor-section__row row"
        >
          <div className="sponsor-section__col">
            <div className="sponsor-section__quote">
              {/* Innovate with */}
              {t('sponsor_type_title_0')}
            </div>
            <div className="sponsor-section__sponsor-logos">
              {sponsorTrans
                ?.filter(
                  (value, index) =>
                    t(`sponsor_logo_type_${index}`) === 'innovate'
                )
                .map((value, index) => {
                  return (
                    <SponsorLogo
                      key={t(`sponsor_logo_type_${value}`)}
                      name={t(`sponsor_logo_name_${value}`)}
                      icon={t(`sponsor_logo_logo_${value}`)}
                    />
                  );
                })}
            </div>
          </div>
          <div className="sponsor-section__col">
            <div className="sponsor-section__quote">
              {t('sponsor_type_title_1')}
            </div>
            <div className="sponsor-section__sponsor-logos">
              {sponsorTrans
                ?.filter(
                  (value, index) =>
                    t(`sponsor_logo_type_${index}`) === 'collaboration'
                )
                .map((value, index) => {
                  return (
                    <SponsorLogo
                      key={t(`sponsor_logo_type_${value}`)}
                      name={t(`sponsor_logo_name_${value}`)}
                      icon={t(`sponsor_logo_logo_${value}`)}
                    />
                  );
                })}
            </div>
          </div>
          <div className="sponsor-section__col sponsor-section__built">
            {/* Built with */}
            <div className="sponsor-section__quote">
              {t('sponsor_type_title_2')}
            </div>
            <div className="sponsor-section__sponsor-logos">
              {sponsorTrans
                ?.filter(
                  (value, index) => t(`sponsor_logo_type_${index}`) === 'built'
                )
                .map((value, index) => {
                  return (
                    <SponsorLogo
                      key={t(`sponsor_logo_type_${value}`)}
                      name={t(`sponsor_logo_name_${value}`)}
                      icon={t(`sponsor_logo_logo_${value}`)}
                    />
                  );
                })}
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}

export default SponsorSection;

type SponsorLogoType = {
  name: string;
  icon: string;
};

function SponsorLogo({ name, icon }: SponsorLogoType) {
  return (
    <div className="sponsor-section__sponsor-logo">
      <img alt={name} src={icon} />
    </div>
  );
}
