import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getHistoryLegacyTranslations } from '../../../services/HistoryPageServices';
import { axiosNoAuth } from '../../../services/axios';
import { HistoryLegacyCard, LegacyCarouselDotType, LegacyCarouselType } from '../../../types/Cards/HistoryLegacyCardType';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import rightArrowImg from '../../../assets/images/icon/forward.svg';
import carouselArrowImg from '../../../assets/images/icon/carouselbutton.svg';
import { Container } from 'react-bootstrap';

function HistoryLegacy() {
  const { t } = useTranslation('recap_legacy');
  const { data: legacyTrans, isLoading } = useQuery({
    queryKey: ['recap_legacy'],
    queryFn: () => getHistoryLegacyTranslations(axiosNoAuth),
    staleTime: Infinity
  });

  if (!legacyTrans || isLoading) return null;
  return(
    <section className='history-legacy'>
      <Container className='history-legacy__container'>
        <div>
          <div className='history-legacy__heading'>{t('legacy_section_name')}</div>
          <h2>{t('legacy_title')}</h2>
        </div>
        {legacyTrans && <LegacyCarousel legacyTrans={legacyTrans} />}
      </Container>
    </section>
  );
}

function LegacyCarousel({legacyTrans}: LegacyCarouselType) {
  const { t } = useTranslation('recap_legacy');
  const responsive = {
    desktop: {
      breakpoint: { max: Infinity,
        min: 1024 },
      items: 4,
      slidesToSlide: 4
    }
  };
  const extraCards = [];
  if( legacyTrans) {
    for (let i = 0; i < 4 - legacyTrans.length % 4; i++) {
      extraCards.push(i);
    }
  }
  return (
    <div>
      <div className='history-legacy__carousel__wrapper'>
        <Carousel
          responsive={responsive}
          containerClass="history-legacy__carousel"
          showDots={true}
          customDot={<LegacyCarouselDot onClick={function (index: number): void { return; } } index={0} active={false} />}
          dotListClass="history-legacy__dot-container"
          partialVisible={true}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup legacyTrans={legacyTrans}/>}
          draggable={false}
          arrows={false}
        >
          {legacyTrans?.map((value, index) => {
            const card = {
              image: t(`legacy_card_image_${index}`),
              title: t(`legacy_card_title_${index}`),
              link: t(`legacy_card_link_${index}`),
              imageAltText: t(`legacy_card_image_alt_text_${index}`),
            };
            return (
              <LegacyCard key={index} card={card} index={index}/>
            );
          })}
          {extraCards.map((_, index) => {
            return(<div key={index} style={{width: '0'}}></div>);
          })}
        </Carousel>
      </div>
      <div className='history-legacy__freescroll'>
        {legacyTrans?.map((value, index) => {
          const card = {
            image: t(`legacy_card_image_${index}`),
            title: t(`legacy_card_title_${index}`),
            link: t(`legacy_card_link_${index}`),
            imageAltText: t(`legacy_card_image_alt_text_${index}`),
          };
          return (
            <LegacyCard key={index} card={card} index={index}/>
          );
        })}
      </div>
    </div>
  );
  function ButtonGroup({ next, previous, goToSlide, ...rest }: any) {
    const { carouselState: { currentSlide } } = rest;
    const isFirstSlide = currentSlide === 0;
    const isLastSlide = currentSlide === legacyTrans.length + extraCards.length - 4;
    return (
      <div className='history-legacy__button-group'>
        <div className={`history-legacy__arrow history-legacy__arrow--left ${!isFirstSlide ? 'history-legacy__arrow--active': ''}`} onClick={previous}>
          <img src={rightArrowImg} className='history-legacy__arrow__icon history-legacy__arrow__icon-left' alt='right arrow'/>
        </div>
        <div className={`history-legacy__arrow history-legacy__arrow--right ${!isLastSlide ? 'history-legacy__arrow--active': ''}`} onClick={next}>
          <img src={rightArrowImg} className='history-legacy__arrow__icon' alt='right arrow' />
        </div>
      </div>
    );
  }

  function LegacyCarouselDot({ onClick, index, active }: LegacyCarouselDotType) {
    return (
      <div
        className={`history-legacy__dot ${active ? 'history-legacy__dot--active' : ''}`}
      />
    );
  }
}

function LegacyCard({ card, index }:  HistoryLegacyCard) {
  return (
    <div className='history-legacy-card__wrapper'>
      <img src={card.image} className='history-legacy-card__img' alt={card.imageAltText}/>
      <a className='history-legacy-card__title' href={card.link}>
        <div className='history-legacy-card__title__text'>
          {card.title}
          <img src={carouselArrowImg} className='history-legacy-card__title__img' alt="legacy card"/>
        </div>
      </a>
    </div>
  );
}

export default HistoryLegacy;
