// import { useState } from 'react';
import { useState, createContext, useContext, useEffect } from 'react';
import { axiosNoAuth } from '../services/axios';
import { Feature, FeatureTogglesContextType, FeatureTogglesProviderType} from '../types/FeatureTogglesContextType';

export const FeatureTogglesContext = createContext<FeatureTogglesContextType>({});

export const useFeatureToggles = () => useContext(FeatureTogglesContext);

export function FeatureTogglesProvider({ children }: FeatureTogglesProviderType) {
  const [featureToggles, setFeatureToggles] = useState({});

  const fetchFeatureToggles = async (): Promise<Feature[]> => {
    try {
      const response = await axiosNoAuth.get('/hackathon-cms/v1/marksite/features');
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    fetchFeatureToggles()
      .then((features) => {
        // Convert the array of features into an object, where the key is the feature name
        const featureTogglesObject = features.reduce((acc: FeatureTogglesContextType, feature: Feature) => {
          acc[feature.name] = feature.enabled;
          return acc;
        }, {});
        setFeatureToggles(featureTogglesObject);
      });
  }, []);

  return (
    <FeatureTogglesContext.Provider value={featureToggles}>
      {children}
    </FeatureTogglesContext.Provider>
  );
}