import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import { initialGoDown, transition, whileInView } from '../../../helpers/animations';
// import {HighlightsCardType} from '../../../types/HighlightSectionType';
import { useTranslation } from 'react-i18next';
import { getHighlightTranslations } from '../../../services/HomePageServices';
import { useQuery } from 'react-query';
import { axiosNoAuth } from '../../../services/axios';

interface HighlightsCardProps {
  card: number;
}

function HighlightsSection() {
  const { t } = useTranslation('highlight');
  const { data: highlightTrans, isLoading } = useQuery({
    queryKey: ['highlight'],
    queryFn: () => getHighlightTranslations(axiosNoAuth),
    staleTime: Infinity
  });

  if (isLoading || !highlightTrans) {
    return null;
  }

  return (
    <section id="event-details" className="highlights-section">
      <Container className='highlights-section__container'>
        <motion.p
          initial={initialGoDown}
          whileInView={whileInView}
          transition={transition}
          className="highlights-section__name"
        >{t('highlight_section_name')}</motion.p>
      </Container>

      <Container>
        <motion.h2
          initial={initialGoDown}
          whileInView={whileInView}
          transition={transition}
          className="highlights-section__title"
        >{t('highlight_title')}</motion.h2>
      </Container>

      <Container>
        <motion.p
          initial={initialGoDown}
          whileInView={whileInView}
          transition={transition}
          className="highlights-section__description"
        >{parse(t('highlight_description'))}</motion.p>
      </Container>
      <Container className='highlights-section__cards-container'>
        <motion.div
          initial={initialGoDown}
          whileInView={whileInView}
          transition={transition}
          className="highlights-section__cards"
        >
          {
            highlightTrans.map((value, index) => {
              return(
                <HighlightsCard key={index} card={index}/>
              );
            })
          }
        </motion.div>
      </Container>
    </section>
  );
}

export default HighlightsSection;

function HighlightsCard({ card }: HighlightsCardProps) {
  const { t } = useTranslation('highlight');
  return (
    <div className="highlights-card">
      <img
        className="highlights-card__img"
        src={t(`highlight_card_image_link_${card}`)}
        alt=""
      />
      <div className="highlights-card__textbox">
        <h4 className="highlights-card__textbox__title">{t(`highlight_card_title_${card}`)}</h4>
        <p className="highlights-card__textbox__text">{parse(t(`highlight_card_description_${card}`))}</p>
      </div>
    </div>
  );
}
