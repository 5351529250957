import { AxiosInstance } from 'axios';
import { NavigationTranslationsType } from '../types/NavigationTranslationsType';
import { i18nAppend } from '../helpers/i18nAppend';

let navigationDataCache: Promise<NavigationTranslationsType> | null = null;

const fetchNavigationData = async (axios: AxiosInstance) => {
  if (!navigationDataCache) {
    navigationDataCache = axios.get('/hackathon-cms/v1/marksite/navigation').then(response => response.data);
  }
  return navigationDataCache;
};

export const getHeaderTranslations = async (axios: AxiosInstance) => {
  const navigationTranslations: NavigationTranslationsType = await fetchNavigationData(axios);

  const headerResponseStructure = {
    application_button_text: '',
    application_link: '',
    application_remarks: '',
    application_remarks_abbr: '',
    cathay_logo: '',
    cathay_logo_alt_text: '',
    id: 0,
    languages_code: '',
    mkt_header_id: '',
    navigation: [],
    recap_icon: '',
    recap_icon_alt_text: '',
    recap_link: '',
    recap_text: '',
    support_icon: '',
    support_icon_alt_text: '',
    support_link: '',
    support_text: '',
    portal_text: '',
    portal_link: ''
  };

  i18nAppend(navigationTranslations.header, 'header', headerResponseStructure);

  return navigationTranslations.header[0].navigation.map((value, index) => {
    return index;
  });
};

export const getFooterTranslations = async (axios: AxiosInstance) => {
  const navigationTranslations: NavigationTranslationsType = await fetchNavigationData(axios);

  const footerResponseStructure = {
    contact_sns: [{
      logo: '',
      link: '',
    }],
    contact_text: '',
    copyright: '',
    id: 0,
    languages_code: '',
    mkt_footer_id: '',
    contact_us_text: '',
    contact_us_link: '',
    terms_and_conditions_text: '',
    terms_and_conditions_link: '',
    cookie_policy_text: '',
    cookie_policy_link: ''
  };

  i18nAppend(navigationTranslations.footer, 'footer', footerResponseStructure);

  return navigationTranslations.footer[0].contact_sns.map((_, index) => {
    return index;
  });
};