import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nextBrowserLanguagedetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(i18nextBrowserLanguagedetector)
  .init({
    resources: {
      'en_HK': {},
      'zh_HK': {},
      'sc_CN': {}
    },
    fallbackLng: 'en_HK',
    debug: false, // set true if wanna debug
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;