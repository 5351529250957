export const initCxDataLayer = function () {
  window.cxDataLayer = window.cxDataLayer || {};
  window.cxDataLayer.page = window.cxDataLayer.page || {};
  window.cxDataLayer.application = window.cxDataLayer.application || {};
  window.cxDataLayer.application = {
    ...window.cxDataLayer.application,
    name: 'AEM',
    version: '',
    env: 'PROD'
  };
};

export const updatePageDataLayer = function (viewport) {
  if (window.cxDataLayer) {
    window.cxDataLayer.page =  {
      ...window.cxDataLayer.page,
      stream: '',
      flow: '',
      page_action: '',
      viewport: viewport
    };
  }
};

export const updatePageview = function (updatedPage) {
  if (window.cxDataLayer && window.cxTealium) {
    const dataLayer = window.cxDataLayer;
    dataLayer.page = updatedPage;
    triggerView();
  }
};

export const updatePageLang = function (pageLang) {
  if (window.cxDataLayer && window.cxTealium) {
    const dataLayer = window.cxDataLayer;
    dataLayer.page.language = pageLang.language;
    dataLayer.page.country = pageLang.country;
    dataLayer.page.locale = pageLang.locale;
    triggerView();
  }
};

export const triggerView = function () {
  if (window.cxDataLayer && window.cxTealium) {
    const dataLayer = window.cxDataLayer;
    window.cxTealium.utag.view(dataLayer);
  }
};

export const triggerEvent = function (event) {
  if (window.cxTealium) {
    const tealiumEvent = event;
    window.cxTealium.utag.link(tealiumEvent);
  }
};

export const conversion = function () {
  if (window.cxTealium) {
    const tealiumConversion = {
      conversion: {
        name: "WEB::HACKATHON::APPLY_NOW_CONVERSION::SUCCESS"
      }
    };
    window.cxTealium.utag.link(tealiumConversion);
  }
};