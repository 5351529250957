import _ from 'lodash';

const checkScrollPosition = (element, offset, callback, resetCallback) => {
  if (_.isEmpty(element)) return;
  // const offsetValue = typeof offset === 'undefined' ? 0 : offset;
  const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
  const isTargetPosition = scrollPosition + window.innerHeight * 0.9 >= element.getBoundingClientRect().top + window.scrollY;
  if (isTargetPosition) {
    callback();
  } else {
    resetCallback();
  }
};
export default checkScrollPosition;
