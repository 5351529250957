import { useTranslation } from 'react-i18next';
import { getHistoryBannerTranslations } from '../../../services/HistoryPageServices';
import { useQuery } from 'react-query';
import { axiosNoAuth } from '../../../services/axios';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import { initialGoDown, whileInView, transition } from "../../../helpers/animations";
import { Container } from 'react-bootstrap';

function HistoryBanner() {
  const { t } = useTranslation('recap_banner');

  const { data: bannerTrans, isLoading } = useQuery({
    queryKey: ['recap_banner'],
    queryFn: () => getHistoryBannerTranslations(axiosNoAuth),
    staleTime: Infinity
  });

  if (!bannerTrans || isLoading) return null;

  return (
    <section className='history-banner'>
      <Container className='history-banner__container'>
        <motion.img
          initial={initialGoDown}
          whileInView={whileInView}
          transition={transition}
          className='history-banner__img'
          src={t('cover_image')}
          alt={t('cover_image_alt_text')}
        />
        <motion.div
          initial={initialGoDown}
          whileInView={whileInView}
          transition={transition}
          className='history-banner__card'
        >
          <div className='history-banner__heading'>{t('cover_section_name')}</div>
          <h2>{t('cover_title')}</h2>
          <div>{parse(t('cover_description'))}</div>
        </motion.div>
      </Container>
    </section>
  );
}

export default HistoryBanner;